/* @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,600&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.App {
    text-align: center;
    color: #00385e;
    padding: 2% 10%;
}

.App-header {
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: calc(10px + 2vmin);
}

.App-header-compress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.App-header-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.nav-link {
    text-decoration: none;
    background-color: white;
}

.nav-link Button {
    font-family: "Poppins", sans-serif;
    color: #00385e;
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

.App p,
ul,
li {
    text-align: left;
    font-size: 20px;
    line-height: 150%;
}

.App li {
    text-align: justify;
}

@media screen and (max-width: 750px) {
    .App li,
    li,
    .App p {
        text-align: left;
        font-size: 14px;
    }

    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.17em;
    }

    h3 {
        font-size: 1em;
    }

    h4 {
        font-size: 0.83em;
    }
}
