@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,600;1,600&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"); */

.App {
    text-align: center;
    color: #00385e;
    padding: 2% 10%;
}

.App-header {
    min-height: 5vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    font-size: calc(10px + 2vmin);
}

.App-header-compress {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.App-header-tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
}

.nav-link {
    text-decoration: none;
    background-color: white;
}

.nav-link Button {
    font-family: "Poppins", sans-serif;
    color: #00385e;
    text-transform: capitalize;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
}

.App p,
ul,
li {
    text-align: left;
    font-size: 20px;
    line-height: 150%;
}

.App li {
    text-align: justify;
}

@media screen and (max-width: 750px) {
    .App li,
    li,
    .App p {
        text-align: left;
        font-size: 14px;
    }

    h1 {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.17em;
    }

    h3 {
        font-size: 1em;
    }

    h4 {
        font-size: 0.83em;
    }
}

